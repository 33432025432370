import React, { useCallback, useEffect, useState } from "react";

import { useAuth0 } from "../react-auth0-spa";
import { useStripeBroker } from '../contexts/StripeBroker';

const ok = (response) => response.status >= 200 && response.status <= 299;
const notOk = (response) => !ok(response);

const PortalCardUpdater = () => {
  const { defaultCard, setDefaultCard } = useStripeBroker();
  const [token, setToken] = useState();
  const { getTokenSilently, isAuthenticated } = useAuth0();

  getTokenSilently()
    .then(token => setToken(token));

  const callAPI = async (sourceUpdatedMessage) => {
    console.log('defaultCard is before callAPI in PortalCardUpdater: ', defaultCard);
    console.log('in custom Portal.callAPI(), stripeSource is:', sourceUpdatedMessage);

    let response;
    try {
      console.log('token in PortalCardUpdater.callAPI: ', token);

      const fetchOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ source: sourceUpdatedMessage.sourceID }),
      };

      // TODO put requireEnvVar in @gpap-utils package and use that for safety
      const defaultPaymentMethodResource = process.env.REACT_APP_STRIPE_BROKER_RESOURCE_DEFAULT_PAYMENT_METHOD;
      console.log('fetching url/options:', defaultPaymentMethodResource, fetchOptions);
      response = await fetch(defaultPaymentMethodResource, fetchOptions);
      console.log('portal card update response: ', response);

      if (notOk(response)) {
        console.error('invalid response: ', response);
        setDefaultCard(undefined);
        return;
      }

      const responseData = await response.json();
      setDefaultCard(responseData);
    } catch (error) {
      console.error(error);
      console.error('response was: ', response);
    }
    return 'contact-ID-withheld'; // TODO does this matter?
  }
  const memoizedCallAPI = useCallback(callAPI, [token]);

  const renderCardUpdaterWidget = () => {
    window.__greenpeace__ && window.__greenpeace__.renderCardUpdater && window.__greenpeace__.renderCardUpdater();
  }

  useEffect(() => {
    // Side effects:
    // 1) Configure CardUpdater with a hook function specific to the current
    // token.
    window.newSource = memoizedCallAPI;
    window.updateCustomer = memoizedCallAPI;

    // 2) (Re-)render the widget
    renderCardUpdaterWidget();
  }, [isAuthenticated, memoizedCallAPI, token]);

  const render = () => {
    if (!isAuthenticated) {
      return <span>not authed, cannot update card</span>
    }

    return (
      <div
        id="gpap-card-updater"
        data-new-source-hook="updateCustomer"
        data-update-customer-hook="updateCustomer"
      ></div>
    );
  }

  return render();
};

export default PortalCardUpdater;
