import React, { useCallback, useEffect, useState, useContext } from "react";

import delayResponse from "../utils/delay";
import { useAuth0 } from "../react-auth0-spa";


export const BrokerContext = React.createContext();
export const useStripeBroker = () => useContext(BrokerContext);


class ServerErrorResponse extends Error { };

async function handleFetchErrors(response: Response) {
  if (response.status === 200) {
    return response.json();
  }

  // Try extracting error message from JSON-body
  // ... but fall back to basic HTTP error response otherwise.
  let error;
  try {
    const body = await response.json();
    const errorMessage = (body && body.message) || response.statusText;
    error = new ServerErrorResponse(errorMessage);
  } catch (e) {
    error = new ServerErrorResponse(response.statusText);
  }
  console.log('handleFetchErrors() throwing: ', error);
  throw error;
}

export const StripeBrokerProvider = ({
  children,
  ...initOptions
}) => {
  // State we export
  const [defaultCard, setDefaultCard] = useState();
  const [cardFetchError, setCardFetchError] = useState();
  const [fetchingCard, setFetchingCard] = useState(false);

  // Internal state
  const [token, setToken] = useState();

  const { getTokenSilently, isAuthenticated } = useAuth0();

  /**
   * Requests default card from Stripe intermediary backend.
   * @return Promise<CardAttributes|Error> 
   */
  const callAPI = () => {
    // let response;
    const service = 'stripe intermediary'
    const defaultPaymentMethodResource = process.env.REACT_APP_STRIPE_BROKER_RESOURCE_DEFAULT_PAYMENT_METHOD;
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    console.log(`fetching from ${service} url: ${defaultPaymentMethodResource} with options: `, fetchOptions);
    // For better UX we delay the response briefly:
    // https://uxmag.com/articles/let-your-users-wait
    // https://ux.stackexchange.com/questions/83277/on-adding-fake-delay-to-network-delays
    // https://www.uxmatters.com/mt/archives/2018/07/handling-delays.php 
    // ^ "If information loads too quickly, it may lose its apparent value to the user."
    // But also we do this to ensure the user sees the <Fetching> text, so they
    // understand we look up their record based on email. 
    const twoSecondDelay = 2 * 1000;
    return fetch(defaultPaymentMethodResource, fetchOptions)
      .then(delayResponse(twoSecondDelay, Date.now()))
      .then(handleFetchErrors)
      .catch((error) => {
        // Occurs when:
        // 1) API sends error, or
        // 2) if there is an error contacting the server
        //    (i.e. either server is down, or user connection failed).

        // _Attempt_ to log to Sentry.
        // Presumably if *user* network is down, this fails (fine -- we don't care!),
        // but if *our backend* sends error or is offline, this should succeed (perfect - report 🔥🔥).
        // const service = 'GPAP API (associate-customer)';
        const problemDescription = error instanceof ServerErrorResponse
          ? `${service} returned non-OK response`
          : `Network failure connecting to ${service}`;
        console.log(`[StripeBroker] ${problemDescription}: `, error);
        try {
          // Sentry.captureException(error, {
          //   extra: problemDescription,
          // });
        } catch (e) {
          // If we can't contact Sentry, or they respond with 4xx (e.g. 429 TOO MANY REQUESTS),
          // the best we can do is log to console.
          console.log('Error from Sentry: ', e);
        }

        return Promise.reject(error);
      });
  }
  const memoizedCallAPI = useCallback(callAPI, [token]);

  useEffect(() => {
    if (isAuthenticated) {
      getTokenSilently().then(token => setToken(token));
    }
  }, [isAuthenticated, getTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && token && !defaultCard) {
      setFetchingCard(true);
      memoizedCallAPI().then((cardDetails) => {
        console.log('Card.js: OK response fetching card: ', cardDetails);
        setDefaultCard(cardDetails);
        setFetchingCard(false);
      }).catch(e => {
        console.log('memoizedCallAPI(): Error calling API: ', e);
        // The suggested means for throwing inside useEffect
        // https://github.com/facebook/react/issues/14981#issuecomment-468460187
        // setState(() => { throw e });
        setCardFetchError(e);
        setFetchingCard(false);
      });
    }
  }, [isAuthenticated, token, memoizedCallAPI, defaultCard, setDefaultCard]);

  return (
    <BrokerContext.Provider
      value={{
        cardFetchError,
        defaultCard,
        fetchingCard,
        setDefaultCard,
      }}
    >
      {children}
    </BrokerContext.Provider>
  );
};
