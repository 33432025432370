// @flow

/**
 * Delay for a number of milliseconds from now (or `startTimestamp` if provided).
 * 
 * @param startTimestamp - Starting time. Defaults to Date.now().
 * @param delayFromStartMS - milliseconds to pause after starting time.
 * 
 * @return a function which takes something (of type T) and resolves to it after
 * desired delay has elapsed.
 * 
 * @example
 *   fetch(resource)
 *    .then(delayResponse(2 * 1000))
 *    .then(handleResponse);
 */
export default function delayedResponse<T>(delayFromStartMS: number, startTimestamp: number):
  (t: T) => Promise<T> {
  const now = Date.now();
  const start = startTimestamp || now;
  const desiredEndTime = start + delayFromStartMS;
  const delayMS = desiredEndTime - start;

  // console.log('[delay] locals', {
  //   now, start, desiredEndTime, delayMS,
  //   nowStr: (new Date(now)),
  //   startStr: (new Date(start)),
  //   desiredEndTimeStr: (new Date(desiredEndTime)),
  // })

  if (delayMS <= 0) {
    return t => Promise.resolve(t);
  }
  return t => new Promise<T>(resolve => setTimeout(() => resolve(t), delayMS));
}
