import React, { Fragment } from "react";

import ErrorBoundary from '../components/ErrorBoundary';
import Hero from "../components/Hero";
import Content from "../components/Content";

const Home = () => (
  <Fragment>
    <ErrorBoundary>
      <Hero />
    </ErrorBoundary>
    <hr />
    <Content />
  </Fragment>
);

export default Home;
