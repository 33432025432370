// @flow
import * as React from 'react';
import { Component } from 'react';
import Highlight from './Highlight';
import BugIcon from './BugIcon';
import errorToString from '../utils/errorToString';
import mailtoLink from '../utils/mailtoLink';


const bugRecipientAddress = 'gpap-engineering-group@greenpeace.org';

type Props = {
  children?: React.Node;

}
type State = {
  error: any, // catch (any)thing that is thrown
}

/**
 * An Error Boundary around the entire App.
 * Catch errors in other error boundaries and do nothing that would throw an error.
 */
class AppErrorBoundary extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ReactErrorInfo) {
    console.log('AppErrorBoundary caught error: ', error, errorInfo);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      const errorAsString = errorToString(error);

      return (
        <div style={{ margin: '2em' }}>
          <h1>
            Well… this is embarrassing.
          </h1>
          <p>
            <BugIcon /><br />
            Looks like we&rsquo;ve dug up a bug!</p>
          <p>
            If this computer bug stopped you from updating your details,
            <b>
              {' '}
              please feel free to
              {' '}
              <a
                href="https://greenpeace.org.au/contact"
                style={{ textDecoration: 'underline' }}
              >
                call us
              </a>
              {' '}
              our friendly supporter relations staff will be happy to assist you
            </b>.
          </p>

          <p>
            If you'd like <b>to help us fix the bug</b>, please
            {' '}
            <a href={
              mailtoLink(
                bugRecipientAddress,
                'FYI: bug found in your website',
                'Hi, I found a bug while using your web support portal.' +
                '\n\n' +
                'This is what I was doing: \n\n' +
                '[ENTER TEXT HERE]. \n\n\n' +
                'The website reported this error: ' +
                errorAsString
              )}
              style={{ textDecoration: 'underline' }}
            >
              email our web team
            </a>
            {' '}
            so we can investigate.
          </p>
          <p>
            Details are also very helpful:
          </p>
          <ul>
            <li>web browser &amp; version</li>
            <li>Operating System &amp; version</li>
            <li>a description of any steps taken just before you saw this error</li>
          </ul>
          <p>
            Thank you in advance!
          </p>
          <p>
            Extra technical bug details for our web team:
          </p>
          <Highlight>
            {
              errorAsString
            }
          </Highlight>
        </div>
      );
    }
    return children || null;
  }
}

export default AppErrorBoundary;
