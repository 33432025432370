const contentData = [
  {
    title: "Together, we're independent",
    link: "https://www.greenpeace.org.au/about/",
    description:
      "More than 95% of our funding comes from generous individual donors, which allows us to make independent decisions and take action on campaigns that matter the most to our supporters."
  },
  // {
  //   title: "Together, we are making waves",
  //   link: "https://greenpeace.org.au",
  //   description:
  //     "With your help, we've already put a stop to whaling, factory farming, nuclear testing, and mansplaining. And tomorrow, we'll do something else."
  // },
  {
    title: "We are peaceful warriors",
    link: "https://www.greenpeace.org.au/about/",
    description:
      "Greenpeace Australia Pacific is an independent environmental campaigning organisation that uses non-violent direct action to work for solutions that are essential to a green and peaceful future."
  },
  // {
  //   title: "Our ships are the shit",
  //   link: "https://www.greenpeace.org.au/about/ships/",
  //   description:
  //     "365 days a year, 24 hours a day, Greenpeace ships are at sea somewhere in the world.",
  // }
];

export default contentData;
