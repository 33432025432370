import React, { useState } from "react";
import { useAuth0 } from "../react-auth0-spa";

const ExternalApi = () => {
  const [showResult, setShowResult] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const { getTokenSilently } = useAuth0();

  const callApi = async () => {
    let response;
    try {
      const token = await getTokenSilently();
      console.log('token in callAPI: ', token);

      const defaultPaymentMethodResource = process.env.REACT_APP_STRIPE_BROKER_RESOURCE_DEFAULT_PAYMENT_METHOD;
      response = await fetch(defaultPaymentMethodResource, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log('got response: ', response);

      const responseData = await response.json();

      setShowResult(true);
      setApiMessage(responseData);
    } catch (error) {
      console.error(error);
      console.error('response was: ', response);
    }
  };

  return (
    <>
      <h1>External API</h1>
      <button onClick={callApi}>Ping API</button>
      {showResult && <code>{JSON.stringify(apiMessage, null, 2)}</code>}
    </>
  );
};

export default ExternalApi;
