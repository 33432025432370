import * as Sentry from '@sentry/browser';
import config from './config';

export default {
  /**
   * Initializes Sentry. Call once in app.
   * "Sentry needs to be initialied before use -
   * ideally before even rendering your react app"
   * @see https://docs.sentry.io/platforms/javascript/react/
   * @return undefined
   */
  init() {
    if (process.env.NODE_ENV === 'test') {
      // no need to log exceptions from tests, across network, to Sentry.io
      return;
    }
    if (process.env.NODE_ENV === 'development') {
      // Development generates errors that would just clutter Sentry for no use
      return;
    }
    Sentry.init(config.sentry);
  },
};
