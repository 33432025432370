import React from "react";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { useStripeBroker } from '../contexts/StripeBroker';

/**
 * Limited card info allowing users to recoginise their card.
 * @typedef {Object} CardAttributes
 * @property {string} brand
 * @property {number} exp_month
 * @property {number} exp_year
 * @property {string} last4
 */


/**
 * Card Render Props
 * @typedef {Object} CardComponentProps
 * @property {CardAttributes} cardAttributes
 */

/**
 * Render a Card.
 * @param {CardComponentProps} props - render props
 */
function Card({ cardAttributes }) {
  if (!cardAttributes) {
    return <NoCard />
  }

  const { brand, exp_month, exp_year, last4 } = cardAttributes;
  return (
    <Cards
      number={`************${last4}`}
      name=' '
      expiry={`${exp_month}/${exp_year}`}
      cvc='***'
      issuer={brand}
      preview={true}
    />
  )
}

const Fetching = () => (
  <span>Looking up card details with the email you provided…</span>
);

const NoCard = () => (
  <span>No default card found</span>
);

const CardContainer = () => {
  const { defaultCard } = useStripeBroker();

  const render = () => {
    if (!defaultCard) {
      return <Fetching />;
    }

    return <Card cardAttributes={defaultCard.card} />;
  }

  return render();
};

export default CardContainer;
