import React from "react";
import ReactDOM from "react-dom";
import sentry from './sentry';
import "./index.css";
import App from "./App";
import AppErrorBoundary from "./components/AppErrorBoundary";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import { StripeBrokerProvider } from "./contexts/StripeBroker";

sentry.init();

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const app = (
  <AppErrorBoundary >
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      audience={config.audience}
      onRedirectCallback={onRedirectCallback}
    >
      <StripeBrokerProvider>
        <App />
      </StripeBrokerProvider>
    </Auth0Provider>
  </AppErrorBoundary>
);

const rootElement = document.getElementById("root");

ReactDOM.render(app, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
