// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { FiAlertTriangle, FiWifiOff } from "react-icons/fi";

type Props = {
  children?: React.Node;
}

type State = {
  error: any, // not sure exactly what form errors will take...
}

type ReactErrorInfo = {
  // If/when React.ErrorInfo is defined, replace ReactErrorInfo with that.
  // see: https://github.com/facebook/flow/issues/5139#issuecomment-431220012
  componentStack: string,
}

function getErrorText(error /*: Error */) {
  if (error.message === 'Failed to fetch') {
    return 'it looks like our network connection isn\'t working';
  }
  return `something unexpected happened: ${error.message}`;
}

function getErrorIcon(error /*: Error */) {
  if (error.message === 'Failed to fetch') {
    return FiWifiOff;
  }
  return FiAlertTriangle;
}

// This ErrorBoundary originally based on the docs:
// https://docs.sentry.io/platforms/javascript/react/
// https://reactjs.org/docs/error-boundaries.html
export default class SentryErrorBoundary extends React.Component<Props, State> {
  static defaultProps = {
    children: undefined,
  }

  static showErrorReportDialog = () => {
    Sentry.showReportDialog();
  }

  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: ReactErrorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      const ErrorIcon = getErrorIcon(error);
      // render fallback UI
      return (
        <div>
          <ErrorIcon style={{ float: 'left', marginRight: '1em' }} /><p><b>Sorry,</b> {getErrorText(error)}. It&rsquo;s not your fault, but you can help us solve it!
          Please let us know how you discovered this issue.</p>
          <button onClick={SentryErrorBoundary.showErrorReportDialog}>Report error so we can fix it</button>
        </div>
      );
    }
    // when there's not an error, render children untouched
    return children || null;
  }
}
