import React from 'react';
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth0 } from "../react-auth0-spa";


function LogoutButton() {
  const { logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    });

  return (
    <Button
      color="primary"
      className="btn-margin"
      onClick={logoutWithRedirect}
    >
      <FontAwesomeIcon icon="power-off" className="mr-3" />
      Log out
    </Button>
  );
}

export default LogoutButton;
