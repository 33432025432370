// @flow
import React from "react";

import Card from './Card';
import LoginButton from './LoginButton';
import PortalCardUpdater from './PortalCardUpdater';

import { useAuth0 } from "../react-auth0-spa";
import { useStripeBroker } from '../contexts/StripeBroker';

import LogoutButton from "./LogoutButton";
import Highlight from "./Highlight";

const LoginSection = () => (
  <p className="lead">
    <LoginButton />
    <span style={{ marginLeft: '0.25em' }}>
      to securely update your&nbsp;Credit&nbsp;Card details.
    </span>
  </p>
);

const CardUpdateSection = () => (
  <>
    <p className="lead">You may now securely update your Credit Card details.</p>
    <div style={{ marginBottom: '48px' }}>
      <p style={{ marginBottom: '16px' }}> Your contributions are currently coming from this card: </p>
      <Card />
    </div>
    <p>You may update the card used for contributions: </p>
    <PortalCardUpdater />
  </>
);

const FetchingSection = () => (
  <p><span>Looking up card details with the email you provided…</span></p>
);

const CallUs = () => (
  <>
    call us at <b>
      <a href="tel:1800-815-151">1800 815 151</a>
    </b> in Australia (
      <a href="tel:+61-2-9281-6100">+61 2 9281 6100</a> if overseas
    ) between 9am-5pm AEST Monday – Friday, and our friendly
    supporter relations team can help update your details.
  </>
)

/**
 * The error to display when no customers match the logged-in email.
 */
const NoRecords = ({ email }: { email: string }) => {
  const pStyle = {
    textAlign: 'left',
    // extra line height to leave space for an inline LogoutButton
    lineHeight: '2em',
  };

  return (
    <>
      <h1>Sorry…</h1>
      <p><b>We couldn&rsquo;t find any records for the email "{email}".</b></p>
      <p style={pStyle}>
        If you have another email possibly associated with your card,
        please <LogoutButton /> and log in with your other email.
      </p>
      <p style={pStyle}>
        Or, <CallUs />
      </p>
    </>
  );
}

const MultipleRecords = ({ email }: { email: string }) => (
  <>
    <h1>Sorry…</h1>
    <p><b>Somehow there may be multiple or duplicated records associated with the email "{email}".</b></p>
    <p style={{ textAlign: 'left' }}>
      Please <CallUs />
    </p>
  </>
);

const UnknownNetworkError = ({ message }: { message: string }) => (
  <>
    <h1>Unexpected error:</h1>
    <Highlight>{message}</Highlight>
    <p>You can try again or <CallUs /></p>
  </>
);

const P = ({ children }) => (
  <p style={{ textAlign: 'left' }}>
    { children }
  </p>
)

const LoginFailure = ({ message }: { message: string }) => (
  <>
    <h1>Sorry…</h1>
    <p>
      We were unable to log you in.
      {' '}
    </p>
    <P>
      Some things to check:
    </P>
    <ul style={{ textAlign: 'left' }}>
      <li>Did you start the login process using this browser/app? (This is required.)</li>
      <li>Are cookies enabled? (We use them to protect your access.)</li>
    </ul>
    <P>
      You are welcome to try again.
    </P>
    <P>
      Otherwise, feel free to <CallUs />
    </P>
    <P>The error from our login system was:</P>
    <Highlight>
    {
      message
    }
    </Highlight>
    <hr />
  </>
)


const ErrorMessage = ({ error }: { error: Error }) => {
  const { user } = useAuth0();

  if (/find any Customer/.test(error.message)) {
    return (<NoRecords email={user.email} />);
  }
  if (/we have multiple.*records/.test(error.message)) {
    return (<MultipleRecords email={user.email} />);
  }

  return <UnknownNetworkError message={error.message} />
}


const Hero = () => {
  const { isAuthenticated, loginError } = useAuth0();
  const { cardFetchError, defaultCard, fetchingCard } = useStripeBroker();

  const haveCardOnFile = !!defaultCard;
  const haveCardFetchError = !!cardFetchError;
  const haveLoginError = !!loginError;

  return (
    <div className="text-center hero my-5">
      {!haveCardFetchError && !haveLoginError && (
        <h1 className="mb-4">Update your card</h1>
      )}

      {haveLoginError && (
        <>
          <LoginFailure message={loginError.message} />
        </>
      )}

      {!isAuthenticated && (
        <LoginSection />
      )}
      {isAuthenticated && haveCardOnFile && (
        <CardUpdateSection />
      )}
      {isAuthenticated && fetchingCard && (
        <FetchingSection />
      )}
      {isAuthenticated && cardFetchError && (
        <ErrorMessage error={cardFetchError} />
      )}
    </div>
  );
}

export default Hero;
