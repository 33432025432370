/**
 * Convert error to string (stringify object or return fallback string).
 * @param error - a throwable error-like thing. Can be Error, an Object, or
 * something else having a .toString() method.
 */
function errorToString(error: any): string {
  if (error instanceof Error || typeof error === 'object') {
    // https://stackoverflow.com/a/26199752
    return JSON.stringify(error, Object.getOwnPropertyNames(error), 1);
  }
  // We can print anything with a .toString method, but note that Functions
  // also have a toString which just prints the function itself - not useful.
  if (typeof error.toString === 'function' && typeof error !== 'function') {
    return error.toString();
  }
  return `Unknown error type: "${typeof error}"`;
}

export default errorToString;
