import React, { Component } from "react";

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from "../assets/logo.svg";
import contentData from "../utils/contentData";

class Content extends Component {
  render() {
    return (
      <section>
        <div className="text-center mt-5">
          <img className="app-logo" src={logo} alt="Logo" width="120" />
        </div>
        <div className="next-steps mt-3">
          <h2 className="mt-3 mb-5 text-center">Your support matters.</h2>
          <Row className="d-flex justify-content-between">
            {contentData.map((col, i) => (
              <Col key={i} md={5} className="mb-4">
                <h6 className="mb-3">
                  <a href={col.link}>
                    <FontAwesomeIcon icon="link" className="mr-2" />
                    {col.title}
                  </a>
                </h6>
                <p>{col.description}</p>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    );
  }
}

export default Content;
