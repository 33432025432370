import React from 'react';
import { Button } from "reactstrap";

import { useAuth0 } from "../react-auth0-spa";


function LoginButton() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      color="primary"
      className="btn-margin"
      onClick={() => loginWithRedirect({})}
    >
      Log in
    </Button>
  );
}

export default LoginButton;
